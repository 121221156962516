import React from 'react';
import "./index.css";
import './webqr.css'; // Import the custom CSS file
import { BrowserRouter, Routes, Route, useParams, Navigate} from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import ErrorPage from './ErrorPage'; // Import the custom error page component

const WebQRPage = () => {
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    // console.log("loading1=="+loading);
    setTimeout(() => {
    const labelData = async () => {
      try {
        // const response = await axios.get(`/api/qr-code/web-qr-detail/${id}`);
        const response = await axios.get(`https://api.zerotag.io/api/qr-code/web-qr-detail/${id}`);
        // const response = await axios.get(`https://zerotag.six30labs.com/api/qr-code/web-qr-detail/${id}`); 
        console.log("result-->"+response.data.result);
        if(response.data.result === "Success"){
          if(response.data.data.Label){
            const dateObject = new Date(response.data.data.Label.prepared_on);
            const today = new Date();
            if(dateObject){
              response.data.data.Label.prepared_on = dateObject.toLocaleString('UTC',{day:'numeric', month:'short', year:'numeric'});
            }
            if(response.data.data.Label.expiary_at){
              const d2 = new Date(response.data.data.Label.expiary_at);
            
              if(d2.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)){
                response.data.data.Label.expiary_at = "expired";
              }
              if(d2.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)){
                response.data.data.Label.expiary_at = "today";
              }
            }
            //  response.data.data.Label = "";
              setData(response.data.data.Label);
              
              setLoading(false);
              // console.log("loading=="+loading);
                // console.log("data==",response.data.data.Label);
          }else{
            //when result==error
            response.data.data.Label = "";
            setData(response.data.data.Label);
            setErrors(false);
            setLoading(false);
            console.log('Else block an error occurred while fetching data=='+data);
          }
        }else{
          setErrors(false);
          console.log('Else block an error occurred while fetching data.');
        }
        // console.log(response);
      } catch (error) {
        setErrors(true);
        console.error('Catch block error fetching data:', error);
        
      }
    };
    labelData();
  }, 1);
  }, []);
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  const handleOnDragStart = (DragEvent) => {
    DragEvent.preventDefault();
  };
  return (
    <div id="wrap" className="w-full md:w-[375px] mx-auto">
      {/* Your content for the WebQR page goes here */}
      {loading ? (
       <div className="preLoadBox aspect-[4/5] w-full  px-4 pb-4 pt-6 pr-4" style={{
        position: 'relative',
        // width: '375px',
        height: '812px',
        background: 'linear-gradient(359.87deg, #E6DDDB -0.72%, #F9F5ED 102.67%)',
        backdropFilter: 'blur(2px)',
      }}>
        {errors ?(
          <div className='errorBox'>
             <p>We encountered a problem...</p>
             <img src="../unexpected_error.png" />
             <p className='contactText'>Contact support@zerotag.au</p>
          </div>
        ):(<img className="preLoadImg" src="./Zerotag-Logo.png" />)} 
      </div>
      
      ):(
      data ?(
        <div className="aspect-[4/5] w-full  px-4 pb-4 pt-6 pr-4" style={{
          position: 'relative',
          // width: '375px',
          height: '812px',
          background: 'linear-gradient(359.87deg, #E6DDDB -0.72%, #F9F5ED 102.67%)',
          backdropFilter: 'blur(2px)',          
        }}>
           {data.description ?(
            <div className="rounded-2xl labelImage  rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[0px] rounded-br-[0px]" onContextMenu={handleContextMenu} onDragStart={handleOnDragStart} 
            style={{backgroundImage:`url(${data.image_url})`,backgroundSize: 'contain',}}>
              {/* <img src={data.image_url} className=" bg-white rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[0px] rounded-br-[0px]" loading="eager"></img> */}
            </div>):
            (<div className="rounded-2xl  labelImage bg-white rounded-[15px] rounded-tr-[15px] rounded-bl-[15px] rounded-br-[15px]" onContextMenu={handleContextMenu} onDragStart={handleOnDragStart} style={{backgroundImage:`url(${data.image_url})`, backgroundSize: 'contain',}}>
              {/* <img src={data.image_url} className=" bg-white rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[0px] rounded-br-[0px]" loading="eager"></img> */}
            
            </div>)
          }
          <div  className="w-full">
            {data.description ? (
              data.image_url?(<div className="bg-[#FFFFFF] alignItems labelDescription">
              <p className='labelFont'>{data.description}</p>
            </div>):(<div className="bg-[#FFFFFF] alignItems noImageLabelDescription"><p className='labelFont'>{data.description}</p></div>)  ):(<div className="alignItems noImageLabelDescription" style={{borderRadius: '0px 0px 12px 12px'}}></div>)}
          </div>
          <div className="w-full px-4 pb-4 pt-6 pr-4 mt-12 flex mb-4 box1" >
            <div className="w-1/2  h-12 text-left">
              <div className=" text-center labelTimilineText">Tagged On</div>
              <div className="text-center svgPreparedOnDiv">
                <div className="timelineBox">
                  <img src="../scribble-grey-icon.svg" alt="scribble" style={{width:'26px',marginRight: '13px',height: '22px'}}/>
                <p className="timelineText" style={{color:'#3D3B46'}}>
                      {data.prepared_on}
                      </p></div>
              </div> 
            </div>
            <div className="w-1/2 h-12 text-right">
              <div className="text-center labelTimilineText usewithinText" style={{/*left:'230px'*/left:'61%',}}>Use Within</div>
              {data.expiary_at === "expired" ?
              (/**expired */
                <div className="text-center svgPreparedOnDiv" style={{left: 'calc(50% - 165px/2 + 84px)',background:' #FF3B30',color: '#FFFFFF',opacity:'1'}}>
                  <div className="timelineBox" >
                  <img src="../expired-outline-icon-white.svg" alt="expired" style={{width:'26px',marginRight: '13px',height:'22px'}}/>
                    <p className='timelineText'>Expired</p></div>
                </div>):
                (data.expiary_at === "today" ? (/**about to expire */
                  <div className="text-center svgPreparedOnDiv" style={{left: 'calc(50% - 165px/2 + 84px)',background:' #FAE100',opacity:'1'}}>
                    <div className="timelineBox" >
                    <img src="../use-within-icon.svg" alt="about to expire" style={{width:'26px',marginRight: '13px',height: '22px'}}/>
                      <p className='timelineText'>{data.used_within} days</p></div>
                  </div>):
                  (/**not expired */
                  <div className="text-center svgPreparedOnDiv" style={{left: 'calc(50% - 165px/2 + 84px)',}}>
                    <div className="timelineBox" >
                    <img src="../use-within-icon.svg" alt="use-within" style={{width:'26px',marginRight: '13px',height: '22px'}}/>
                      <p className='timelineText' style={{color:'#3D3B46'}}>{data.used_within} days</p></div>
                  </div>
                  ))}
              </div>
            </div>
        {/* </div> */}
        </div>
      ):
      (<div className="aspect-[4/5] w-full  px-4 pb-4 pt-6 pr-4" style={{
        position: 'relative',
        // width: '375px',
        height: '812px',
        background: 'linear-gradient(359.87deg, #E6DDDB -0.72%, #F9F5ED 102.67%)',
        backdropFilter: 'blur(2px)',
      }}>
          <div className='emptyBox'>
            <p>Empty</p>
          </div>
      </div>
      )
      )}
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/:id" element={<WebQRPage />} />
      <Route path="/error" element={<ErrorPage />} /> {/* Route for the custom error page */}
    </Routes>
  </BrowserRouter>
  );
};

export default App;